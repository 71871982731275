import { FC, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  linearGradient?: boolean;
  color?: string;
  fontSize?: string | number;
} & PropsWithChildren;

const ImageCaption: FC<Props> = ({ children, fontSize = '14px', color = '#fff', linearGradient }) => (
  <Box
    sx={{
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      padding: '20px',
      textAlign: 'center',
      borderRadius: '0 0 1.5rem 1.5rem',
      ...(linearGradient && { background: 'linear-gradient(transparent, #000)' }),
    }}
  >
    <Typography fontSize={fontSize} fontWeight="500" color={color}>
      {children}
    </Typography>
  </Box>
);

export default ImageCaption;
